var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            attrs: { type: "primary", size: "medium" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              staticStyle: { width: "100%", float: "left", "margin-left": "0" },
              attrs: {
                "label-position": "left",
                "label-width": "200px",
                model: _vm.formInline,
              },
            },
            [
              _c("span", { staticClass: "messTitle" }, [_vm._v("订单信息")]),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "停车场名称:", prop: "parkName" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.parkName))])]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "手机号:", prop: "mobile" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.mobile))])]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "车牌号:", prop: "plateNumber" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.formInline.plateNumber &&
                          _vm.formInline.plateNumber.indexOf("无") != -1
                          ? "无牌车"
                          : _vm.formInline.plateNumber
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "同步MS状态:", prop: "syncStatusName" },
                },
                [
                  _c(
                    "span",
                    { class: _vm._f("colorFilter")(_vm.formInline.syncStatus) },
                    [_vm._v(_vm._s(_vm.formInline.syncStatusName))]
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "订购时间:", prop: "payTime" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.payTime))])]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "支付方式:", prop: "payTypeName" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.payTypeName))])]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "订单金额:", prop: "actualMoney" },
                },
                [
                  _vm.formInline.actualMoney
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formInline.actualMoney / 100) + "元"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("span", { staticClass: "messTitle" }, [_vm._v("商品信息")]),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "销售金额:", prop: "mobile" },
                },
                [
                  _vm.formInline.staggeredPrice
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.formInline.staggeredPrice / 100) + "元/天"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "margin-bottom": "0",
                  },
                  attrs: { label: "有效时间:", prop: "mobile" },
                },
                _vm._l(_vm.formInline.orderDetailList, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(item.startTime + " - " + item.endTime)),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }